import * as Icons from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Empty,
  Progress,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import { EnrrolmentObservationModal } from 'components/modal/enrrolment/EnrrolmentObservationModal';
import { IDocumentation } from 'interfaces/documentation/documentations';
import { IEnrollment } from 'interfaces/enrollments';
import { ShowFieldData } from 'components/ShowFieldData';
import { fetchSummaryDocumentation } from 'services/graduate-enrollment/summary';
import { formatDate } from 'services/date';
import { tagColorByStatus } from 'utils/statusColorMapping';
import { translateDocumentationStatus } from 'services/documentations';
import { useCallback, useEffect, useState } from 'react';
import { useModal, useNotification } from '@refinedev/core';

interface SummaryProps {
  enrollment: IEnrollment;
}

export const TabSummary = ({ enrollment }: SummaryProps) => {
  const [documentation, setDocumentation] = useState<IDocumentation>();
  const { open } = useNotification();
  const observationModal = useModal();

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchSummaryDocumentation(enrollment.id);

      if (res.length > 0) {
        setDocumentation(res[0]);
      }
    } catch {
      open?.({
        type: 'error',
        message: 'Erro ao recuperar documentações.',
        description: 'Ops!'
      });
    }
  }, [enrollment.id, open]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const observationModalModal = () => {
    observationModal.show();
  };

  return (
    <Space direction="vertical" size="small" className="w-full">
      <Typography.Title level={4}>Aluno</Typography.Title>
      <Row>
        <Col xs={24} md={12}>
          <ShowFieldData label="Nome" value={enrollment.user.name} />
        </Col>
        <Col xs={24} md={12}>
          <ShowFieldData label="Email" value={enrollment.user.email} />
        </Col>
      </Row>

      <Divider />

      <Typography.Title level={4}>Matrícula</Typography.Title>
      <Row>
        <Col xs={24} md={5}>
          <ShowFieldData
            label="Número de matrícula"
            value={enrollment.registration}
          />
        </Col>
        <Col xs={24} md={5}>
          <ShowFieldData
            label="Data de início"
            value={formatDate(enrollment.created_at, 'DD/MM/YYYY')}
          />
        </Col>
        <Col xs={24} md={5}>
          <ShowFieldData
            label="Data de expiração"
            value={formatDate(enrollment.expires_at, 'DD/MM/YYYY')}
          />
        </Col>
        <Col xs={24} md={5}>
          <ShowFieldData
            label="Curso concluído em"
            value={formatDate(enrollment.expires_at, 'DD/MM/YYYY')}
          />
        </Col>
        <Col xs={24} md={4}>
          <ShowFieldData
            label="Progresso"
            value={
              <Tooltip title={`${enrollment.progress_percentage}%`}>
                <Progress
                  size="small"
                  showInfo={false}
                  percent={enrollment.progress_percentage}
                />
              </Tooltip>
            }
          />
        </Col>
      </Row>

      <Divider />

      <Space size="middle" className="items-center mb-1">
        <Typography.Title level={4} className="!mb-0">
          Documentação
        </Typography.Title>

        {documentation?.id && (
          <Button
            href={`/academic/documentations/show/${documentation?.id}`}
            icon={<Icons.EyeOutlined />}
          >
            Visualizar
          </Button>
        )}
      </Space>

      {!documentation?.id ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Nenhuma documentação recebida"
        />
      ) : (
        <ShowFieldData
          label="Status"
          value={
            <Tag color={tagColorByStatus(documentation.status)}>
              {translateDocumentationStatus(documentation.status)}
            </Tag>
          }
        />
      )}
      <Divider />
      <Space size="middle" className="items-center mb-1">
        <Typography.Title level={4} className="!mb-0">
          Observações do histórico escolar
        </Typography.Title>
        <Button onClick={observationModalModal} icon={<Icons.EditOutlined />}>
          Editar
        </Button>
      </Space>
      <Alert
        type="info"
        showIcon
        message="Esta informação será exibida no campo de observações do histórico
        escolar"
      />
      <Typography.Text>{enrollment.observation}</Typography.Text>
      <EnrrolmentObservationModal
        modal={observationModal}
        enrrolment={enrollment}
      />
    </Space>
  );
};
